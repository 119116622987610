import React from 'react';
import Card from './style';
export default function TestiomialCard({
  userName,
  userPosition,
  text,
  image,
  ...rest
}) {
  return (
    <Card backgroundColor="#fff">
      <img src={image} alt="Testimonial" style={{ width: '100%' }} />
    </Card>
  );
}
