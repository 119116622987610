import React from 'react';
import TestiomialCard from './Components/Card';
import SectionTitle from './Components/SectionTitle';
import { Images } from '~data';
import Testimonial from './style';
import { Col, Container, Row } from 'react-bootstrap';

import dhsLogo from '../../../assets/image/dhs.png';
import airforceLogo from '../../../assets/image/airforce.png';
import letItBeLogo from '../../../assets/image/libus.png';

export default function TestimonialSection() {
  return (
    <Testimonial backgroundColor="#f3f4f6">
      <Container>
        <Row className="justify-content-center">
          <Col className="col-lg-9 col-xl-9 col-xxl-7 text-center">
            <SectionTitle
              title="Our Customers"
              subTitleProps={{ mb: '27px' }}
              titleProps={{ mb: '10px', as: 'h2' }}
              mb="50px"
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="col-xl-4 col-md-6">
            <TestiomialCard image={dhsLogo} />
          </Col>
          <Col className="col-xl-4 col-md-6">
            <TestiomialCard image={airforceLogo} />
          </Col>
          <Col className="col-xl-4 col-md-6">
            <TestiomialCard image={letItBeLogo} />
          </Col>
        </Row>
      </Container>
    </Testimonial>
  );
}
